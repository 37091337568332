export const formatterMixin = {
  methods: {
    formatDate (date, includeYear = true) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      if (includeYear) {
        return `${month}/${day}/${year}`
      } else { return `${month}/${day}` }
    },
    parseDate (date) {
      if (date.includes('T')) {
        return new Date(Date.parse(date))
      } else {
        return new Date(Date.parse(date + 'T00:00:00'))
      }
    },
    formatJSDate (date) {
      return date.getFullYear() + '-' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))
    },
    formatIsoDate (date, includeHour = false) {
      if (date === null) {
        return ''
      }
      var d = this.parseDate(date)
      if (!includeHour) {
        return d.toLocaleDateString()
      } else {
        return d.toLocaleString()
      }
    },
    getMonthName (month) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return months[month - 1]
    },
    formatAbbreviatedDate (date, includeYear = false, delimeter = '/') {
      if (!date) return null
      const [year, month, day] = date.split('-')
      const monthName = this.getMonthName(month).substring(0, 3)

      if (includeYear) {
        return `${monthName}${delimeter}${day}${delimeter}${year}`
      } else { return `${monthName}${delimeter}${day}` }
    },
    formatFullDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      const monthName = this.getMonthName(month)
      return `${monthName} ${day}, ${year}`
    },
    formatPhoneNumber (phoneNumberString) {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return ['(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return ''
    },
    formatNewUsed: function (used) {
      if (used) {
        return 'Used'
      } else {
        return 'New'
      }
    },
    formatPrice: function (price) {
      const priceFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      })
      return priceFormatter.format(Number(price).toFixed(0))
    },
    formatPriceNoZero: function (price) {
      if (price === null) {
        return ''
      }
      const priceFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      })
      return priceFormatter.format(Number(price).toFixed(0))
    },
    formatPriceWithCents: function (price) {
      const priceFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      return priceFormatter.format(Number(price).toFixed(2))
    },
    formatNumber: function (num) {
      return Number(num).toLocaleString()
    },
    formatNumberNoZero: function (num) {
      if (num === null) {
        return ''
      }
      return Number(num).toLocaleString()
    },
    formatPercent (value) {
      if (parseInt(value) >= 0) {
        return `${value} %`
      } else {
        return ''
      }
    },
    formatNumberToString (value) {
      if (value === null || value === '') {
        return 0
      } else {
        return value.toString()
      }
    },
    formattedCurrentDate () {
      const d = new Date().toISOString().substring(0, 10)
      return d
    },
    formatBoolean (value) {
      if (value) {
        return 'Yes'
      } else { return 'No' }
    },
    capitalizeFirstLetter (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    unattributedSaleName () {
      return 'Unattributed Sales'
    },
    fancyTimeFormat (duration) {
      // Hours, minutes and seconds
      var hrs = ~~(duration / 3600)
      var mins = ~~((duration % 3600) / 60)
      var secs = ~~duration % 60

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = ''

      if (hrs > 0) {
          ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
      }

      ret += '' + mins + ':' + (secs < 10 ? '0' : '')
      ret += '' + secs
      return ret
    },
    sumField (items, key, formatter) {
      return formatter(items.reduce((a, b) => a + (b[key] || 0), 0))
    },
  },
}
